export function retry(fn, retriesLeft = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          // console.log(`retries left: ${retriesLeft}`);

          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            // console.error('maximum retries exceeded');
            // console.error(error);

            return window.location.reload();
            // reject(error);
            // return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
