import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const FullScreenLoading = () => (
  <LoadingContainer>
    <LoadingIndicator />
  </LoadingContainer>
);

export default FullScreenLoading;
