import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// import { ThemeProvider } from 'styled-components';
// import { ApolloProvider } from '@apollo/client';
// import { ToastProvider } from 'react-toast-notifications';
// import { BrowserRouter } from 'react-router-dom';
// import moment from 'moment';
// import GlobalStyle from './GlobalStyle';
// import App from './App';
// import apolloClient from './apolloClient';
// import { UserStoreProvider } from './stores/UserStore';
// import { GeneralGlobalStoreProvider } from './stores/GeneralGlobalStore';
// import { EventsOverviewFilterProvider } from './stores/EventsOverviewFilterStore';
// import theme from './theme';
// import AppWrapper from './AppWrapper';
// import 'nprogress/nprogress.css';
// import './customNprogressStyle.css';
// import 'react-datepicker/dist/react-datepicker.css';
import { retry } from './utils/retry';
import FullScreenLoading from './elements/FullScreenLoading';

// require('moment/locale/de');
// moment.locale('de');
// moment.weekdays(true);

// apolloClient
//   .query({
//     query: gql`
//       {
//         messages {
//           _id
//           text
//         }
//       }
//     `,
//   })
//   .then(result => {
//     console.log(result);
//   });

// Sentry.init({
//   dsn: 'https://ff3c9dff196d47fca546f1b3a0f7bb74@o311026.ingest.sentry.io/5276741',
//   integrations: [
//     new Sentry.Integrations.Breadcrumbs({
//       console: false,
//     }),
//   ],
// });

Sentry.init({
  dsn: 'https://ff3c9dff196d47fca546f1b3a0f7bb74@o311026.ingest.sentry.io/5276741',
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const AppWrapper = lazy(() => retry(() => import('./AppWrapper')));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<FullScreenLoading />}>
    <AppWrapper />
  </Suspense>
);
