import React from 'react';
import styled from 'styled-components';
import { Heart } from 'react-spinners-css';

const Container = styled.div`
  /* margin-top: 50px; */
  text-align: center;
`;

const LoadingIndicator = () => {
  return (
    <Container>
      <Heart />
    </Container>
  );
};

export default LoadingIndicator;
